// guardMyRoute.js
import jwtDecode from 'jwt-decode'; // Asegúrate de tener la librería jwt-decode instalada
import store from '@/store'; // Importa tu store

export default function guardMyRoute(to, from, next) {
  // Verificar si el token está presente y es válido
  const token = localStorage.getItem("token");
  console.log("token: " + token);

  if (token) {
    const decoded = jwtDecode(token);
    console.log("token dec: " + decoded);
    const isExpired = decoded.exp < Date.now() / 1000;

    if (isExpired) {
      localStorage.removeItem("token"); // Remover el token expirado
      store.commit('logout'); // Asegurarse de limpiar el estado de autenticación
      next({ name: "Login" });
      return; // Salir de la función para evitar llamadas adicionales a next
    }
    console.log("token valido");

    // Proceder con las verificaciones de rutas que requieren autenticación
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!store.getters.getIsLoggedIn) {
        next({ name: "Login" });
      } else if (to.path.includes("corredor") && store.getters.role === 1) {
        next();
      } else if (!to.path.includes("corredor") && store.getters.role === 1) {
        next({ name: "Corredor" });
      } else if (to.path.includes("admin") && store.getters.role === 4) {
        next();
      } else if (!to.path.includes("admin") && store.getters.role === 4) {
        next({ name: "adminRequests" });
      } else {
        next();
      }
    } else {
      next();
    }

    // Manejar rutas que deben ocultarse después del inicio de sesión
    if (to.matched.some(record => record.meta.hideForAuth)) {
      if (store.getters.getIsLoggedIn) {
        if (store.getters.role === 1) {
          next({ name: "Corredor" });
        } else if (store.getters.role === 4) {
          next({ name: "adminRequests" });
        } else {
          next();
        }
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      next({ name: "Login" });
    } else {
      next();
    }
  }
}

