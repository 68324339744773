<template>
  <b-form @submit.prevent="onSubmit">
    <input-password
      :id="idPassword1"
      v-model="form.password1"
      label="Nueva contraseña"
      :required="true"
      @onChange="updateInputValue"
    />
    <input-password
      :id="idPassword2"
      v-model="form.password2"
      label="Repite tu contraseña"
      :required="true"
      @onChange="updateInputValue"
    />
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button type="submit" variant="primary" size="lg"
          >Crear contraseña</b-button
        >
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import InputPassword from "@/components/misc/InputPassword.vue";

import Config from "@/../config.json";

import axios from "axios";

export default {
  name: "FormNewPassword",
  components: {
    InputPassword,
  },
  data() {
    return {
      form: {
        password1: "",
        password2: "",
      },
      idPassword1: "password-1",
      idPassword2: "password-2",
      rawPassword1: "",
      rawPassword2: "",
      minLength: 8,
    };
  },
  methods: {
    async onSubmit(event) {
      try {
        event.preventDefault();

        if (this.rawPassword1 !== this.rawPassword2) {
          this.$bvToast.toast("Ambas contraseñas deben ser iguales.", {
            title: `Error`,
            variant: "danger",
            solid: true,
          });
          return;
        }

        if (
          this.rawPassword1.length < this.minLength ||
          this.rawPassword2.length < this.minLength
        ) {
          this.$bvToast.toast(
            "La nueva contraseña debe tener al menos 8 caracteres",
            {
              title: `Error`,
              variant: "danger",
              solid: true,
            }
          );
          return;
        }

        await axios.patch(Config.VUE_APP_URL_PASSWORD_BY_UUID, {
          uuid: this.$route.params.uuid,
          newPassword: this.rawPassword1,
        });

        this.$router.push("/contraseña-creada");
      } catch (error) {
        this.$bvToast.toast("UUID no válido.", {
          title: `Error`,
          variant: "danger",
          solid: true,
        });
      }
    },
    updateInputValue({ id, value }) {
      if (id === this.idPassword1) this.rawPassword1 = value;
      if (id === this.idPassword2) this.rawPassword2 = value;
    },
  },
};
</script>
