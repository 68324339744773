import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "../views/misc/NotFound.vue";
import UserLogin from "../views/user/UserLogin.vue";
import VerificationMessage from "../views/user/VerificationMessage.vue";
import UserRegister from "../views/user/UserRegister.vue";
import VerifiedAccount from "../views/user/VerifiedAccount.vue";
import TestView from "../views/TestView.vue";
import RecoverPassword from "../views/password/RecoverPassword";
import EmailSend from "../views/password/EmailSend";
import NewPassword from "../views/password/NewPassword";
import PasswordSuccess from "../views/password/PasswordSuccess";
import shareDocument from "../views/document/shareDocument";
import realtor from "./realtor";
import admin from "./admin";
import guardMyRoute from "./guardMyRoute";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    beforeEnter: guardMyRoute,
    component: UserLogin,
    meta: {
      title: "Inicio de sesión",
      hideForAuth: true,
    },
  },
  {
    path: "/verificacion",
    name: "VerificationMessage",
    beforeEnter: guardMyRoute,
    component: VerificationMessage,
    meta: {
      title: "Verificación",
    },
  },
  {
    path: "/test",
    name: "Test",
    component: TestView,
    meta: {
      title: "Test",
    },
  },
  {
    path: "/registro",
    name: "Register",
    component: UserRegister,
    meta: {
      title: "Registro",
    },
  },
  {
    path: "/cuenta-verificada/:uuid",
    name: "VerifiedAccount",
    beforeEnter: guardMyRoute,
    component: VerifiedAccount,
    meta: {
      title: "Cuenta verificada",
    },
  },
  {
    path: "/recuperar-contraseña",
    name: "RecoverPassword",
    component: RecoverPassword,
    meta: {
      title: "Recupera tu contraseña",
    },
  },
  {
    path: "/correo-enviado",
    name: "EmailSend",
    component: EmailSend,
    meta: {
      title: "Correo enviado",
    },
  },
  {
    path: "/nueva-contraseña/:uuid",
    name: "NewPassword",
    component: NewPassword,
    meta: {
      title: "Crea tu nueva contraseña",
    },
  },
  {
    path: "/contraseña-creada",
    name: "PasswordSuccess",
    component: PasswordSuccess,
    meta: {
      title: "¡Contraseña creada con éxito!",
    },
  },
  {
    path: "/compartido/:uuid",
    name: "ShareDocument",
    component: shareDocument,
    meta: {
      title: "Documentos de propiedad compartida",
    },
  },
  {
    path: "*",
    redirect: "/not-found",
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "Error",
    },
  },
  ...realtor,
  ...admin,
];

const router = new VueRouter({
  routes,
  mode: "history",
  linkExactActiveClass: "active-nav-exact",
  linkActiveClass: "active-nav",
});

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  ? `${to.meta.title} - iProp`
  : 'iProp'

  // document.title = to.meta.title ? to.meta.title : "iProp";
  next();
});

export default router;
