<template>
  <b-form novalidate @submit.prevent="onSubmit">
    <b-form-group id="input-group-1" label="Correo electrónico" label-for="input-1" class="font-weight-bold">
      <b-form-input id="input-1" v-model.trim="form.email" :state="emailState" type="email"
        placeholder="Ingresa tu correo" size="lg" @keydown.space.prevent @paste.prevent @blur.native="validateEmail()">
      </b-form-input>
      <b-form-invalid-feedback>
        {{ hasBlurred.email.content }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group id="input-group-2" label="Contraseña" label-for="input-2" class="font-weight-bold">
      <b-form-input id="input-2" v-model.trim="form.password" :state="passwordState" placeholder="Ingresa tu contraseña"
        type="password" size="lg" @keydown.space.prevent @copy.prevent @paste.prevent @blur.native="validatePassword()">
      </b-form-input>
      <b-form-invalid-feedback>
        {{ hasBlurred.password.content }}
      </b-form-invalid-feedback>
    </b-form-group>
    
    <b-row class="mb-4"> 
      <b-col class="text-right">
        <b-button variant="link" href="/recuperar-contraseña">
          Olvidé mi contraseña
        </b-button>
      </b-col>
    </b-row>
    
    <b-button block class="my-4" type="submit" variant="primary" size="lg">
      Ingresar
    </b-button>
    
    <p class="mb-5">
      ¿No tienes cuenta?
      <router-link :to="{ name: 'Register' }">Regístrate aquí</router-link>
    </p>
    
  </b-form>
</template>

<script>
export default {
  name: "FormLogin",
  data() {
    return {
      regEmail: /^(([^<>àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœñ()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      responseCode: 0,
      form: {
        status: "not_accepted",
        email: "",
        password: "",
        isRegistering: false,
      },
      hasBlurred: {
        email: {
          state: null,
          content: ''
        },
        password: {
          state: null,
          content: ''
        },
      },
    };
  },
  computed: {
    emailState() {
      return this.hasBlurred.email.state;
    },
    passwordState() {
      return this.hasBlurred.password.state;
    },
  },
  methods: { 
    async onSubmit(event) {
      event.preventDefault();
      this.validateEmail();
      this.validatePassword();

      if (this.hasBlurred.email.state === null && this.hasBlurred.password.state === null) {
        await this.$store.dispatch("loginUser", this.form);
        if (this.$store.getters.getUserData == null) {
          this.$bvToast.toast(this.$store.getters.getMsg, {
            title: "¡Hubo un problema!",
            solid: true,
            variant: "danger",
          });
        }
      }
    },
    validateEmail() {
      if (this.form.email === '') {
        this.hasBlurred.email.state = false;
        this.hasBlurred.email.content = 'Debes ingresar tu correo.';
      } else {
        this.hasBlurred.email.state = this.regEmail.test(this.form.email) ? null : false;
        this.hasBlurred.email.content = 'Debes ingresar un correo válido.';
      }
    },
    validatePassword() {
      if (this.form.password === '') {
        this.hasBlurred.password.state = false;
        this.hasBlurred.password.content = 'Debes ingresar tu contraseña.';
      } else {
        this.hasBlurred.password.state = this.form.password.length > 7 ? null : false;
        this.hasBlurred.password.content = 'Contraseña debe tener al menos 8 caracteres.';
      }
    }
  },
};
</script>
